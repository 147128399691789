import { createTheme, responsiveFontSizes } from "@mui/material/styles"

const primaryColor = "#D0492F"
const secondaryColor = "#18315A"
const infoColor = "#4B8D82"

const breakpoints = createTheme({}).breakpoints

let rawTheme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
    info: {
      main: infoColor,
    },
    text: {
      secondary: "#ffffff",
      primary: "#333333",
    },
  },
  typography: {
    fontFamily: ['"Titillium Web"', "sans-serif"].join(", "),
    overline: {
      fontSize: "1.25rem",
      lineHeight: 0.85,
      textTransform: "uppercase",
    },
    body1: {
      fontSize: "1.125rem",
      fontFamily: "Open Sans",
    },
    caption: {
      fontWeight: 400,
    },
    h1: {
      fontSize: "3.375em",
      color: "#ffffff",
      // lineHeight: "1.58125em",
      marginBottom: "0.2em",
      "@media (max-width: 767px)": {
        fontSize: "2.25em",
      },
    },
    h2: {
      fontSize: "3.375rem",
    },
    h3: {
      fontSize: "3rem",
    },
    h4: {
      fontSize: "2.25rem",
    },
    h5: {
      fontSize: "1.625rem",
    },
    h6: {
      fontSize: "1.375rem",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          color: "#fff",
          [breakpoints.up("md")]: {
            "&:hover": {
              backgroundColor: "#fff",
              color: "#333333",
            },
          },
        },

        outlinedPrimary: {
          backgroundColor: "#fff",
          color: secondaryColor,
          "&:hover": {
            backgroundColor: primaryColor,
            color: "#fff",
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "#333",
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          paddingTop: "1em",
          paddingBottom: "1em",
        },
      },
    },
  },
})

export const theme = responsiveFontSizes(rawTheme)

import React from "react"
import { theme } from "@components/theme"
import {  StyledEngineProvider, ThemeProvider } from "@mui/material"

export default function TopLayout({ children }) {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  )
}
